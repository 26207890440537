import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import * as ReactBootStrap from "react-bootstrap";
import GetAllFournisseurs from "../controller/model/GetAllFournisseurs";
import searchListFou from "../../utils/search/searchListFou";

export class FournisseursList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fournisseurs: [],
      loading: false,
      valueSearch: "",
      field: "FOU_NAME",
    };
    this.inputChange = this.inputChange.bind(this);
  }
  inputChange = input => async e => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    await this.setState({ loading: false });
    const { valueSearch, field } = this.state;
    const fournisseurs = await GetAllFournisseurs();
    if (valueSearch) {
      const res = await searchListFou(fournisseurs, field, valueSearch);
      await this.setState({ fournisseurs: res });
    } else {
      await this.setState({ fournisseurs: fournisseurs });
    }
    this.setState({ loading: true });
  };
  async componentWillMount() {
    const fournisseurs = await GetAllFournisseurs();
    await this.setState({ fournisseurs: fournisseurs });
    this.setState({ loading: true });
  }
  render() {
    const { fournisseurs, loading } = this.state;

    return (
      <div class="card card-custom gutter-b">
        <div class="card-header border-0 py-5">
          <div class="card-toolbar">
            <div class="card-toolbar">
              <div class="dropdown dropdown-inline mr-2">
                <select
                  style={{
                    backgroundColor: "rgb(218 8 6 / 81%)",
                    color: "white",
                    fontSize: "initial",
                  }}
                  class="form-control"
                  id="exampleSelectd"
                  name="field"
                  onChange={this.inputChange()}
                  defaultValue="FOU_NAME"
                >
                  <option value="FOU_NAME"> Nom </option>
                  <option value="FOU_EMAIL"> Email </option>
                  <option value="FOU_ZONE"> Secteur </option>
                </select>
              </div>
            </div>
            <div
              class="dropdown dropdown-inline mr-2"
              style={{
                display: "inline-flex",
              }}
            >
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="valueSearch"
                placeholder="Rechercher"
                onChange={this.inputChange()}
              />
            </div>
          </div>
        </div>
        <div class="card-body py-0">
          <div class="table-responsive">
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_2"
            >
              <thead>
                <tr class="text-uppercase">
                  <th class="pl-0" className="tb-id">
                    id
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">Nom fournisseur</span>
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">Email</span>
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">Secteur d'activité</span>
                  </th>
                  <th className="tb-others">
                    <span class="text-primary">Téléphone</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  fournisseurs.map((v, i) => {
                    return (
                      <>
                        <tr>
                          <td class="pl-0">
                            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {i + 1}
                            </span>
                          </td>
                          <td>
                            <Link
                              to={`/fournisseurs/${v.FOU_GUID}`}
                              class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                            >
                              {v.FOU_NAME}
                            </Link>
                          </td>
                          <td>
                            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {v.FOU_EMAIL}
                            </span>
                          </td>
                          <td>
                            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {v.FOU_ZONE}
                            </span>
                          </td>
                          <td>
                            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {v.FOU_PHONE}
                            </span>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <ReactBootStrap.Spinner animation="border" />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default FournisseursList;
