"use strict";
import React from "react";
import Notifications from "./Notifications";
import Languages from "./Languages";
import UserProfil from "./UserProfil";
const Topbar = () => {
  return (
    <div class="topbar">
      <Notifications />,
      <Languages />,
      <UserProfil />
    </div>
  );
};

export default Topbar;
