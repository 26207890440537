import { authentification } from "./auth.dao";
import { deleteSession } from "../session/session.controller";
/**
 *
 * @param {*} USR_EMAIL
 * @param {*} USR_PASSWORD
 * @param {*} remember
 * @returns {Object{data, status}}
 */
async function findByEmailPasswork(USR_EMAIL, USR_PASSWORD, remember = false) {
  try {
    const response = await authentification(USR_EMAIL, USR_PASSWORD);
    const { status, data } = response;
    if (status === 200) {
      remember
        ? localStorage.setItem("tokenCrm", data.SES_GUID)
        : sessionStorage.setItem("tokenCrm", data.SES_GUID);
    }
    console.log(response);
    return response;
  } catch (error) {
    return error.response;
  }
}
/**
 *
 * @returns
 */
async function disconnect() {
  return await deleteSession();
}

function validator({ USR_EMAIL, USR_PASSWORD }) {
  let errors = {
    exist: false,
    message: {
      USR_EMAIL: "",
      USR_PASSWORD: "",
    },
  };
  if (!USR_EMAIL) {
    errors.exist = true;
    errors.message.USR_EMAIL = "Email obligatoire";
  } else if (!/\S+@\S+\.\S+/.test(USR_EMAIL)) {
    errors.exist = true;
    errors.message.USR_EMAIL = "Adresse Email invalide";
  }
  if (!USR_PASSWORD) {
    errors.exist = true;
    errors.message.USR_PASSWORD = "Mot de passe obligatoire";
  }
  return errors;
}

export default {};
export { disconnect, findByEmailPasswork, validator };
