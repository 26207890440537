import React from "react";

const Gestionnaires = () => {
  return (
    <div class="tab-pane px-7" id="kt_user_edit_tab_2" role="tabpanel">
      <div class="card card-custom gutter-b">
        <div class="card-body py-0">
          <div class="table-responsive">
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_2"
            >
              <thead>
                <tr class="text-uppercase">
                  <th class="pl-0" style={{ minWidth: "50px" }}>
                    {" "}
                    id
                  </th>
                  <th style={{ minWidth: "180px" }}>
                    <span class="text-primary">Prénom Nom</span>
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    <span class="text-primary">Dernière modification</span>
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    <span class="text-primary">Créateur du contact</span>
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    <span class="text-primary">Rôle</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="pl-0">
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                      1
                    </span>
                  </td>
                  <td>
                    <a
                      href="information_employe.html"
                      class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                    >
                      56037-XDER
                    </a>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                      05/28/2020
                    </span>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Intertico
                    </span>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                      05/28/2020
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gestionnaires;
