"use strict";
import React from "react";
import ButtonAdd from "../utils/button/ButtonAdd";
import ButtonPageTitle from "../utils/button/ButtonPageTitle";
import ContactsList from "./composants/ContactsList";

const Contacts = () => {
  return (
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <ButtonPageTitle title="CONTACTS" />
      <div class="d-flex flex-column-fluid">
        <div class="container">
          <ButtonAdd ressource="contact" />
          <ContactsList />
        </div>
      </div>
    </div>
  );
};

export default Contacts;
