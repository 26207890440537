import React, { Component } from "react";

export class SendMail extends Component {
  quitTempMail = () => {
    this.props.handleSendMail();
  };
  render() {
    const { tempMail } = this.props;

    return (
      <div
        class="modal modal-sticky modal-sticky-bottom-right"
        style={{
          width: "80%",
          height: "800px",
          border: "1px solid black",
          display: tempMail,
          position: "fixed",
          zIndex: "1",
          bottom: 0,
          maxWidth: "900px",
          overflowY: "scroll",
        }}
      >
        <div class="card card-custom">
          <div class="card-header align-items-center px-4 py-3">
            <div class="text-center flex-grow-1">
              <div class="text-dark-75 font-weight-bold font-size-h2">
                Matt Pears
              </div>
            </div>
            <div class="text-right flex-grow-1">
              <button type="button" onClick={this.quitTempMail}>
                <i class="ki ki-close icon-1x"></i>
              </button>
            </div>
          </div>
          <div class="card-header align-items-center px-4 py-3">
            <label class="col-form-label" style={{ marginRight: "30px" }}>
              Sélectionnez le Fournisseur
            </label>
            <div class="text-center flex-grow-1">
              <div>
                <select style={{ fontSize: "1.8em" }} class="form-control">
                  <option value="2" data-select2-id="22">
                    Duplicate
                  </option>
                  <option value="0" data-select2-id="20">
                    Enhancement
                  </option>
                  <option value="1" data-select2-id="21">
                    Bug
                  </option>
                  <option value="3" data-select2-id="23">
                    Invalid
                  </option>
                  <option value="4" data-select2-id="24">
                    Wontfix
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-header align-items-center px-4 py-3">
            <label class="col-form-label" style={{ marginRight: "90px" }}>
              Objet du mail
            </label>
            <div class="text-center flex-grow-1">
              <input
                class="form-control form-control-lg form-control-solid"
                type="text"
                value="Nick"
              />
            </div>
          </div>

          <div class="card-body">
            <h3 class="card-label font-weight-bolder text-dark">
              Fiche de RDV:
            </h3>
            <div class="py-9">
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Email:</span>
                <a href="#" class="text-muted text-hover-primary">
                  matt@fifestudios.com
                </a>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Phone:</span>
                <span class="text-muted">44(76)34254578</span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <span class="font-weight-bold mr-2">Location:</span>
                <span class="text-muted">Melbourne</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Email:</span>
                <a href="#" class="text-muted text-hover-primary">
                  matt@fifestudios.com
                </a>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Phone:</span>
                <span class="text-muted">44(76)34254578</span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <span class="font-weight-bold mr-2">Location:</span>
                <span class="text-muted">Melbourne</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Email:</span>
                <a href="#" class="text-muted text-hover-primary">
                  matt@fifestudios.com
                </a>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Phone:</span>
                <span class="text-muted">44(76)34254578</span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <span class="font-weight-bold mr-2">Location:</span>
                <span class="text-muted">Melbourne</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Email:</span>
                <a href="#" class="text-muted text-hover-primary">
                  matt@fifestudios.com
                </a>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Phone:</span>
                <span class="text-muted">44(76)34254578</span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <span class="font-weight-bold mr-2">Location:</span>
                <span class="text-muted">Melbourne</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Email:</span>
                <a href="#" class="text-muted text-hover-primary">
                  matt@fifestudios.com
                </a>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Phone:</span>
                <span class="text-muted">44(76)34254578</span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <span class="font-weight-bold mr-2">Location:</span>
                <span class="text-muted">Melbourne</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Email:</span>
                <a href="#" class="text-muted text-hover-primary">
                  matt@fifestudios.com
                </a>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Phone:</span>
                <span class="text-muted">44(76)34254578</span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <span class="font-weight-bold mr-2">Location:</span>
                <span class="text-muted">Melbourne</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Email:</span>
                <a href="#" class="text-muted text-hover-primary">
                  matt@fifestudios.com
                </a>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Phone:</span>
                <span class="text-muted">44(76)34254578</span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <span class="font-weight-bold mr-2">Location:</span>
                <span class="text-muted">Melbourne</span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <span class="font-weight-bold mr-2">Location:</span>
                <span class="text-muted">Melbourne</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Email:</span>
                <a href="#" class="text-muted text-hover-primary">
                  matt@fifestudios.com
                </a>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Phone:</span>
                <span class="text-muted">44(76)34254578</span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <span class="font-weight-bold mr-2">Location:</span>
                <span class="text-muted">Melbourne</span>
              </div>
            </div>
          </div>
          <div
            class="card-footer align-items-center"
            style={{
              position: "fixed",
              bottom: 0,
              width: "60%",
              backgroundColor: "white",
              paddingTop: 0,
              maxWidth: "890px",
            }}
          >
            <div class="d-flex align-items-center justify-content-between mt-5">
              <button
                type="button"
                class="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6"
              >
                Envoyer
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SendMail;
