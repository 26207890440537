import { destroy, findOne } from "./session.dao";

async function getByStorage() {
  let tokenCrm = sessionStorage.getItem("tokenCrm");
  if (!tokenCrm) {
    tokenCrm = localStorage.getItem("tokenCrm");
  }
  if (!tokenCrm) {
    return false;
  } else {
    try {
      const response = await findOne(tokenCrm);
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

/**
 *
 * @returns {Object{data, status }}
 */
async function deleteSession() {
  let tokenCrm = sessionStorage.getItem("tokenCrm");
  if (!tokenCrm) {
    tokenCrm = localStorage.getItem("tokenCrm");
  }
  if (!tokenCrm) {
    return false;
  } else {
    try {
      const response = await destroy(tokenCrm);
      localStorage.clear();
      sessionStorage.clear();
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

export default {};
export { deleteSession, getByStorage };
