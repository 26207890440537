"use strict";
import React from "react";
const NotificationsList = () => {
  return (
    <div class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
      <form>
        <div class="tab-content">
          <div
            class="tab-pane active show p-8"
            id="topbar_notifications_notifications"
            role="tabpanel"
          >
            <div
              class="scroll pr-7 mr-n7"
              data-scroll="true"
              data-height="300"
              data-mobile-height="200"
            >
              <div class="d-flex align-items-center mb-6">
                <div class="d-flex flex-column font-weight-bold">
                  <a
                    href="#"
                    class="text-dark text-hover-primary mb-1 font-size-lg"
                  >
                    Cool App
                  </a>
                </div>
              </div>

              <div class="d-flex align-items-center mb-6">
                <div class="d-flex flex-column font-weight-bold">
                  <a
                    href="#"
                    class="text-dark text-hover-primary mb-1 font-size-lg"
                  >
                    Bruk Fitness
                  </a>
                </div>
              </div>
            </div>
            <div class="d-flex flex-center pt-7">
              <a
                href="#"
                class="btn btn-light-primary font-weight-bold text-center"
              >
                Marquer tout comme lu
              </a>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NotificationsList;
