import API from "../utils/api/api";

function authentification(USR_EMAIL, USR_PASSWORD) {
  const ajaxAction = "connect";
  return API.post("adm_ses.php", {
    USR_EMAIL,
    USR_PASSWORD,
    ajaxAction,
  });
}

export default {};
export { authentification };
