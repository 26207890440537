import React, { Component } from "react";
import { Link } from "react-router-dom";
import DeleteFournisseur from "../controller/model/DeleteFournisseur";

export class GererFournisseur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      error: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick = async () => {
    const { guid } = this.props;
    const res = await DeleteFournisseur(guid);
    if (res) {
      await this.setState({ show: true });
    } else {
      await this.setState({ error: true });
    }
  };
  render() {
    const { show, error } = this.state;
    return (
      <div class="tab-pane px-7" id="kt_user_edit_tab_2" role="tabpanel">
        {show ? (
          <>
            <p>
              Le fournisseur vient d'être suppimer de la base de données
              définitivement.
            </p>
            <hr />
            <Link
              to="/fournisseurs"
              class="btn btn-light-primary font-weight-bold"
            >
              Liste des fournisseurs
            </Link>
          </>
        ) : (
          <>
            <div class="card-body">
              <div class="row" style={{ backgroundColor: "white" }}>
                <div class="col-xl-2"></div>
                <div class="col-xl-7">
                  <div class="row mb-5">
                    <label class="col-3"></label>
                    {error && (
                      <h6 class="text-dark font-weight-bold mb-10">
                        Une erreur c'est produite, reessayer !
                      </h6>
                    )}
                    <div class="col-9">
                      <div class="row">
                        <label class="col-3"></label>
                        <div class="col-9">
                          <h6 class="text-dark font-weight-bold mb-10">
                            Supprimer ce dossier fournisseur
                          </h6>
                        </div>
                      </div>
                      <div
                        class="alert alert-custom alert-light-danger fade show py-4"
                        role="alert"
                      >
                        <div class="alert-icon">
                          <i class="flaticon-warning"></i>
                        </div>
                        <div class="alert-text font-weight-bold">
                          En supprimant ce dossier, toutes les informations du
                          fournisseur seront perdues.
                        </div>
                        <div class="alert-close">
                          <button
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer pb-0" style={{ paddingTop: 0 }}>
              <div class="row">
                <div class="col-xl-2"></div>
                <div class="col-xl-7">
                  <div class="row">
                    <div class="col-3"></div>
                    <div class="col-9">
                      <button
                        class="btn btn-light-primary font-weight-bold"
                        onClick={this.handleClick}
                      >
                        Supprimer fournisseur
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </>
        )}
      </div>
    );
  }
}

export default GererFournisseur;
