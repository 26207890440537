import React from "react";
import DetailsInfo from "./DetailsInfo";
import Gestionnaires from "./Gestionnaires";
import GererContact from "./GererContact";

const Content = () => {
  return (
    <div class="card card-custom " id="contact-info-affichage">
      <div class="card-body">
        <div class="tab-content">
          <DetailsInfo />
          <Gestionnaires />
          <GererContact />
        </div>
      </div>
    </div>
  );
};

export default Content;
