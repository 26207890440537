import React from "react";

const Autorisation = () => {
  return (
    <div class="tab-pane px-7" id="kt_user_edit_tab_2" role="tabpanel">
      <div class="row" style={{ backgroundColor: "white" }}>
        <div class="my-2">
          <div class="row">
            <label class="col-form-label col-3 text-lg-right text-left"></label>
            <div class="col-9">
              <h6 class="text-dark font-weight-bold mb-10">Account:</h6>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Username
            </label>
            <div class="col-9">
              <div class="spinner spinner-sm spinner-success spinner-right spinner-input">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  value="nick84"
                />
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Email Address
            </label>
            <div class="col-9">
              <div class="input-group input-group-lg input-group-solid">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="la la-at"></i>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  value="nick.watson@loop.com"
                  placeholder="Email"
                />
              </div>
              <span class="form-text text-muted">
                Email will not be publicly displayed.
                <a href="#">Learn more</a>.
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Language
            </label>
            <div class="col-9">
              <select class="form-control form-control-lg form-control-solid">
                <option>Select Language...</option>
                <option value="id">Bahasa Indonesia - Indonesian</option>
                <option value="msa">Bahasa Melayu - Malay</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-3 text-lg-right text-left">
              Time Zone
            </label>
            <div class="col-9">
              <select class="form-control form-control-lg form-control-solid">
                <option
                  data-offset="-39600"
                  value="International Date Line West"
                >
                  (GMT-11:00) International Date Line West
                </option>
                <option data-offset="-39600" value="Midway Island">
                  (GMT-11:00) Midway Island
                </option>
                <option data-offset="-39600" value="Samoa">
                  (GMT-11:00) Samoa
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-form-label col-3 text-lg-right text-left">
              Communication
            </label>
            <div class="col-9">
              <div class="checkbox-inline">
                <label class="checkbox">
                  <input type="checkbox" checked="checked" />
                  <span></span>Email
                </label>
                <label class="checkbox">
                  <input type="checkbox" checked="checked" />
                  <span></span>SMS
                </label>
                <label class="checkbox">
                  <input type="checkbox" />
                  <span></span>Phone
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Autorisation;
