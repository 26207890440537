import React from "react";

const Commentaires = () => {
  return (
    <div class="container">
      <h6 class="text-dark font-weight-bold mb-10">Commentaires</h6>
      <form class="form">
        <div class="form-group">
          <textarea
            class="form-control form-control-lg form-control-solid"
            id="exampleTextarea"
            rows="3"
            placeholder="Ajouter commentaire"
          ></textarea>
        </div>
        <div class="row">
          <div class="col">
            <button
              href="#"
              class="btn btn-light-primary font-weight-bold"
              style={{ backgroundColor: "#adadad" }}
            >
              Enregister
            </button>
          </div>
        </div>
      </form>
      <div class="timeline timeline-3">
        <div class="timeline-items">
          <div class="timeline-item">
            <div class="timeline-media">
              <img alt="Pic" src="assets/media/users/300_25.jpg" />
            </div>
            <div class="timeline-content">
              <div class="d-flex align-items-center justify-content-between mb-3">
                <div class="mr-2">
                  <a
                    href="#"
                    class="text-dark-75 text-hover-primary font-weight-bold"
                  >
                    New order has been placed
                  </a>
                  <span class="text-muted ml-2">Today</span>
                  <span class="label label-light-success font-weight-bolder label-inline ml-2">
                    new
                  </span>
                </div>
              </div>
              <p class="p-0">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commentaires;
