import React from "react";

const ContactAffecte = () => {
  return (
    <div class="tab-pane px-7" id="kt_user_edit_tab_4" role="tabpanel">
      <div class="card card-custom gutter-b">
        <div class="card-header border-0 py-5">
          <div class="card-toolbar">
            <div class="dropdown dropdown-inline mr-2">
              <button
                type="button"
                class="btn btn-light-primary font-weight-bolder dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="svg-icon svg-icon-md"></span>Filtrer sur :{" "}
              </button>
              <div class="dropdown-menu dropdown-menu-sm dropdown-menu-left">
                <ul class="navi flex-column navi-hover py-2">
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-text">Clients</span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-text">Prospects</span>
                    </a>
                  </li>
                  <li class="navi-item">
                    <a href="#" class="navi-link">
                      <span class="navi-text">Date de RDV</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body py-0">
          <div class="table-responsive">
            <table
              class="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_2"
            >
              <thead>
                <tr class="text-uppercase">
                  <th class="pl-0 tb-id" id="tb-id">
                    id
                  </th>
                  <th className="tb-name">
                    <span class="text-primary">Prénom Nom</span>
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">Date RDV</span>
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">Type de RDV</span>
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">Lieu du RDV</span>
                  </th>
                  <th className="tb-name">
                    <span class="text-primary">Ajouter par</span>
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">Type de projet</span>
                  </th>
                  <th className="tb-others">
                    <span class="text-primary">Code postal</span>
                  </th>
                  <th className="tb-date">
                    <span class="text-primary">L’issu du RDV</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="pl-0">
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                      1
                    </span>
                  </td>
                  <td>
                    <a
                      href="information_contact.html"
                      class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                    >
                      56037-XDER
                    </a>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                      05/28/2020
                    </span>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Intertico
                    </span>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                      05/28/2020
                    </span>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                      05/28/2020
                    </span>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Intertico
                    </span>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                      05/28/2020
                    </span>
                  </td>
                  <td>
                    <span class="label label-lg label-light-primary label-inline">
                      Approved
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactAffecte;
