import React from "react";

const Step3 = () => {
  return (
    <div class="pb-5" data-wizard-type="step-content">
      <h4 class="mb-10 font-weight-bold text-dark">Select your Services</h4>
      <div class="form-group">
        <label>Delivery Type</label>
        <select
          name="delivery"
          class="form-control form-control-solid form-control-lg"
        >
          <option value="">Select a Service Type Option</option>
          <option value="overnight" selected="selected">
            Overnight Delivery (within 48 hours)
          </option>
          <option value="express">
            Express Delivery (within 5 working days)
          </option>
          <option value="basic">
            Basic Delivery (within 5 - 10 working days)
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>Packaging Type</label>
        <select
          name="packaging"
          class="form-control form-control-solid form-control-lg"
        >
          <option value="">Select a Packaging Type Option</option>
          <option value="regular" selected="selected">
            Regular Packaging
          </option>
          <option value="oversized">Oversized Packaging</option>
          <option value="fragile">Fragile Packaging</option>
          <option value="frozen">Frozen Packaging</option>
        </select>
      </div>
      <div class="form-group">
        <label>Preferred Delivery Window</label>
        <select
          name="preferreddelivery"
          class="form-control form-control-solid form-control-lg"
        >
          <option value="">Select a Preferred Delivery Option</option>
          <option value="morning" selected="selected">
            Morning Delivery (8:00AM - 11:00AM)
          </option>
          <option value="afternoon">
            Afternoon Delivery (11:00AM - 3:00PM)
          </option>
          <option value="evening">Evening Delivery (3:00PM - 7:00PM)</option>
        </select>
      </div>
    </div>
  );
};

export default Step3;
