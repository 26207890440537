import React from "react";

const GererContact = () => {
  return (
    <div class="tab-pane px-7" id="kt_user_edit_tab_3" role="tabpanel">
      <div class="card-body">
        <div class="row" style={{ backgroundColor: "white" }}>
          <div class="col-xl-2"></div>
          <div class="col-xl-7">
            <div class="row mb-5">
              <label class="col-3"></label>
              <div class="col-9">
                <div class="row">
                  <label class="col-3"></label>
                  <div class="col-9">
                    <h6 class="text-dark font-weight-bold mb-10">
                      Supprimer ce dossier contact
                    </h6>
                  </div>
                </div>
                <div
                  class="alert alert-custom alert-light-danger fade show py-4"
                  role="alert"
                >
                  <div class="alert-icon">
                    <i class="flaticon-warning"></i>
                  </div>
                  <div class="alert-text font-weight-bold">
                    En supprimant ce dossier, toutes les informations du contact
                    seront perdues.
                  </div>
                  <div class="alert-close">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <i class="la la-close"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer pb-0" style={{ paddingTop: 0 }}>
        <div class="row">
          <div class="col-xl-2"></div>
          <div class="col-xl-7">
            <div class="row">
              <div class="col-3"></div>
              <div class="col-9">
                <a href="#" class="btn btn-light-primary font-weight-bold">
                  Supprimer contact
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GererContact;
