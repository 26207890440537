import React, { Component } from "react";
import SendMail from "../composants/SendMail";

export class Recap extends Component {
  constructor(props) {
    super(props);
    this.state = { tempMail: "none" };
    this.handleSendMail = this.handleSendMail.bind(this);
  }
  handleSendMail() {
    if (this.state.tempMail === "none") {
      this.setState({ tempMail: "block" });
    }
    if (this.state.tempMail === "block") {
      this.setState({ tempMail: "none" });
    }
  }

  render() {
    return (
      <div>
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="d-flex mb-9">
              <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                  <span class="font-size-h3 symbol-label font-weight-boldest">
                    JM
                  </span>
                </div>
              </div>
              <div class="flex-grow-1">
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex mr-3">
                    <a
                      href="#"
                      class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                    >
                      Jason Muller
                    </a>
                  </div>
                  <div class="my-lg-0 my-3">
                    <button
                      to="#"
                      class="btn btn-sm btn-info font-weight-bolder text-uppercase"
                      data-target="#kt_chat_modal"
                      onClick={this.handleSendMail}
                    >
                      Envoyer email
                    </button>
                  </div>
                </div>
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <div class="info-contact d-flex flex-wrap mb-4">
                      <i class="flaticon2-new-email mr-2 font-size-lg"></i>
                      jason@siastudio.com
                    </div>
                    <div class="d-flex flex-wrap mb-4">
                      <i class="flaticon2-calendar-3 mr-2 font-size-lg"></i>PR
                      Manager
                    </div>
                    <div class="d-flex flex-wrap mb-4">
                      <i class="flaticon2-placeholder mr-2 font-size-lg"></i>
                      Melbourne
                    </div>
                    <div class="d-flex flex-wrap mb-4">
                      <i class="flaticon2-placeholder mr-2 font-size-lg"></i>
                      Melbourne
                    </div>
                  </div>
                  <div class="d-flex align-items-center w-25 flex-fill float-right mt-lg-12 mt-8">
                    <div class="info-centrer d-flex flex-column text-dark-75">
                      <span class="titre-info-centrer ">Date de RDV</span>
                      <span class="label label-lg label-light-danger label-inline font-weight-bolder font-size-sm">
                        7 MAI 2021
                      </span>
                    </div>
                    <div class="info-centrer d-flex flex-column text-dark-75">
                      <span class="titre-info-centrer">Date d'ajout</span>
                      <span class="label label-lg font-weight-bolder font-size-sm label-light-success label-inline">
                        7 MAI 2021
                      </span>
                    </div>
                    <div class="info-centrer d-flex flex-column text-dark-75">
                      <span class="titre-info-centrer ">Fournisseur</span>
                      <span class="label label-lg font-weight-bolder font-size-sm label-light-dark label-inline">
                        K2M Developpement
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="separator separator-solid"></div>
            <div class="d-flex align-items-center flex-wrap mt-8">
              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="flaticon-piggy-bank display-4 text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Earnings</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span>249,500
                  </span>
                </div>
              </div>
              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="flaticon-confetti display-4 text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Expenses</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span>164,700
                  </span>
                </div>
              </div>
              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="flaticon-pie-chart display-4 text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Net</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span>782,300
                  </span>
                </div>
              </div>
              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="flaticon-pie-chart display-4 text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Net</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span>782,300
                  </span>
                </div>
              </div>
              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="flaticon-pie-chart display-4 text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Net</span>
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">$</span>782,300
                  </span>
                </div>
              </div>
              <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span class="mr-4">
                  <i class="flaticon-chat-1 display-4 text-muted font-weight-bold"></i>
                </span>
                <div class="d-flex flex-column">
                  <span class="text-dark-75 font-weight-bolder font-size-sm">
                    648 Comments
                  </span>
                  <a href="#" class="text-primary font-weight-bolder">
                    View
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SendMail
          tempMail={this.state.tempMail}
          handleSendMail={this.handleSendMail}
        />
      </div>
    );
  }
}

export default Recap;
