import React from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import StepActions from "./StepActions";

const Content = () => {
  return (
    <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
      <div class="col-xl-12 col-xxl-7">
        <form class="form" id="kt_form">
          <Step1 />
          <Step2 />
          <Step3 />
          <Step4 />
          <Step5 />
          <StepActions />
        </form>
      </div>
    </div>
  );
};

export default Content;
