import React from "react";

const Step2 = () => {
  return (
    <div class="pb-5" data-wizard-type="step-content">
      <h4 class="mb-10 font-weight-bold text-dark">
        Enter the Details of your Delivery
      </h4>
      <div class="form-group">
        <label>Package Details</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          name="package"
          placeholder="Package Details"
          value="Complete Workstation (Monitor, Computer, Keyboard &amp; Mouse)"
        />
        <span class="form-text text-muted">
          Please enter your Pakcage Details.
        </span>
      </div>
      <div class="form-group">
        <label>Package Weight in KG</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          name="weight"
          placeholder="Package Weight"
          value="25"
        />
        <span class="form-text text-muted">
          Please enter your Package Weight in KG.
        </span>
      </div>
      <div class="row">
        <div class="col-xl-4">
          <div class="form-group">
            <label>Package Width in CM</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="width"
              placeholder="Package Width"
              value="110"
            />
            <span class="form-text text-muted">
              Please enter your Package Width in CM.
            </span>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="form-group">
            <label>Package Height in CM</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="height"
              placeholder="Package Height"
              value="90"
            />
            <span class="form-text text-muted">
              Please enter your Package Height in CM.
            </span>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="form-group">
            <label>Package Length in CM</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="packagelength"
              placeholder="Package Length"
              value="150"
            />
            <span class="form-text text-muted">
              Please enter your Package Length in CM.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
