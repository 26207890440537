"use strict";
import React from "react";
import { Link } from "react-router-dom";
const profil = (
  <div
    class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
    id="kt_quick_user_toggle"
  >
    <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
      Hi,
    </span>
    <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
      Sean
    </span>
    <span class="symbol symbol-35 symbol-light-success">
      <span class="symbol-label font-size-h5 font-weight-bold">S</span>
    </span>
  </div>
);

const UserProfil = () => {
  return (
    <div class="dropdown">
      <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px">
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>
          <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            Sean
          </span>
          <span class="symbol symbol-35 symbol-light-success">
            <span class="symbol-label font-size-h5 font-weight-bold">S</span>
          </span>
        </div>
      </div>
      <div class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right">
        <ul class="navi navi-hover py-4">
          <li class="navi-item">
            <Link to="/deconnexion" class="navi-link">
              Deconnexion
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserProfil;
