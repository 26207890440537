import React from "react";

const Historiques = () => {
  return (
    <div class="container" id="historique">
      <h6 class="text-dark font-weight-bold mb-10">Historiques</h6>
      <div class="d-flex align-items-center">
        <div class="symbol symbol-40 symbol-light-success mr-5">
          <span class="symbol-label">
            {/* <img src="assets/media/svg/avatars/047-girl-25.svg" class="h-75 align-self-end" alt=""> */}
          </span>
        </div>
        <div class="d-flex flex-column flex-grow-1">
          Thioro DIOP
          <span class="font-weight-bolder" style={{ display: "contents" }}>
            {" "}
            a créé le contact
          </span>
          <span class="text-muted font-weight-bold">Yestarday at 5:06 PM</span>
        </div>
      </div>
    </div>
  );
};

export default Historiques;
