import React from "react";

const Step2 = () => {
  return (
    <div class="pb-5" data-wizard-type="step-content">
      <h4 class="mb-10 font-weight-bold text-dark">
        Setup Your Delivery Location
      </h4>
      <div class="form-group">
        <label>Address Line 1</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          name="locaddress1"
          placeholder="Address Line 1"
          value="Address Line 1"
        />
        <span class="form-text text-muted">Please enter your Address.</span>
      </div>
      <div class="form-group">
        <label>Address Line 2</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          name="locaddress2"
          placeholder="Address Line 2"
          value="Address Line 2"
        />
        <span class="form-text text-muted">Please enter your Address.</span>
      </div>
      <div class="row">
        <div class="col-xl-6">
          <div class="form-group">
            <label>Postcode</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="locpostcode"
              placeholder="Postcode"
              value="3072"
            />
            <span class="form-text text-muted">
              Please enter your Postcode.
            </span>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="form-group">
            <label>City</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="loccity"
              placeholder="City"
              value="Preston"
            />
            <span class="form-text text-muted">Please enter your City.</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6">
          <div class="form-group">
            <label>State</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="locstate"
              placeholder="State"
              value="VIC"
            />
            <span class="form-text text-muted">Please enter your State.</span>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="form-group">
            <label>Country</label>
            <select
              name="loccountry"
              class="form-control form-control-solid form-control-lg"
            >
              <option value="">Select</option>
              <option value="AF">Afghanistan</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
