import React from "react";
import { Link } from "react-router-dom";

const EmployesList = () => {
  return (
    <div class="card card-custom gutter-b">
      <div class="card-body py-0">
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center"
            id="kt_advance_table_widget_2"
          >
            <thead>
              <tr class="text-uppercase">
                <th class="pl-0 tb-id"> id</th>
                <th class="tb-name">
                  <span class="text-primary">Prénom Nom</span>
                </th>
                <th class="tb-name">
                  <span class="text-primary">Contact Affecté</span>
                </th>
                <th class="tb-name">
                  <span class="text-primary">Date d'ajout</span>
                </th>
                <th class="tb-name">
                  <span class="text-primary">Profil</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="pl-0">
                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                    1
                  </span>
                </td>
                <td>
                  <Link
                    to="/info-employe"
                    class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                  >
                    56037-XDER
                  </Link>
                </td>
                <td>
                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                    05/28/2020
                  </span>
                </td>
                <td>
                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                    Intertico
                  </span>
                </td>
                <td>
                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                    05/28/2020
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EmployesList;
