import React from "react";
import AddNav from "./AddNav";
import Steps from "./Steps";

const Content = () => {
  return (
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
          class="wizard wizard-1"
          id="kt_wizard"
          data-wizard-state="step-first"
          data-wizard-clickable="false"
        >
          <AddNav />
          <Steps />
        </div>
      </div>
    </div>
  );
};

export default Content;
