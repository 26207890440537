import React, { Component } from "react";
import HeaderMobile from "./ressources/utils/header-mobile/HeaderMobile";
import Dashboard from "./ressources/dashboard/Dashboard";
import Employes from "./ressources/employes/Employes";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import Header from "./ressources/utils/body/header/Header";
import LeftBar from "./ressources/utils/body/left-bar/LeftBar";
import Footer from "./ressources/utils/body/Footer";
import Contacts from "./ressources/contacts/Contacts";
import AddContact from "./ressources/contacts/AddContact";
import Info from "./ressources/contacts/Info";
import Fournisseurs from "./ressources/fournisseurs/Fournisseurs";
import AddFournisseur from "./ressources/fournisseurs/AddFournisseur";
import InfoFournisseur from "./ressources/fournisseurs/InfoFournisseur";
import AddEmploye from "./ressources/employes/AddEmploye";
import InfoEmploye from "./ressources/employes/InfoEmploye";
import Profil from "./ressources/profil/Profil";
import NotFoundPage from "./ressources/utils/NotFoundPage";
import Login from "./ressources/auth/Login";
import Deconnexion from "./ressources/auth/Deconnexion";
import * as ReactBootStrap from "react-bootstrap";
import { getByStorage } from "./ressources/session/session.controller";

const history = createBrowserHistory();

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: false,
      loading: false,
    };
    this.authListener = this.authListener.bind(this);
  }
  async componentDidMount() {
    await this.authListener();
  }
  async authListener() {
    const session = await getByStorage();
    console.log(session);
    if (session.status === 200) {
      await this.setState({ user: true });
    }
    this.setState({ loading: true });
  }
  render() {
    const { user, loading } = this.state;

    return (
      <Router history={history}>
        {loading ? (
          <div id="d-flex" class="d-flex flex-column flex-root">
            {user ? (
              <div class="d-flex flex-row flex-column-fluid page">
                <HeaderMobile />
                <LeftBar />
                <div
                  class="d-flex flex-column flex-row-fluid wrapper"
                  id="kt_wrapper"
                >
                  <Header />
                  {/* <Switch> */}
                  <Route path="/fournisseurs" exact component={Fournisseurs} />
                  <Route
                    path="/ajouter-fournisseur"
                    exact
                    component={AddFournisseur}
                  />
                  <Route
                    path="/fournisseurs/:guid"
                    exact
                    component={InfoFournisseur}
                  />

                  <Route path="/employes" exact component={Employes} />
                  <Route path="/ajouter-employe" exact component={AddEmploye} />
                  <Route path="/info-employe" exact component={InfoEmploye} />

                  <Route path="/contacts" exact component={Contacts} />
                  <Route path="/ajouter-contact" exact component={AddContact} />
                  <Route path="/info-contact" exact component={Info} />

                  <Route path="/profil" exact component={Profil} />

                  <Route path="/" exact component={Dashboard} />
                  <Route path="/deconnexion" exact component={Deconnexion} />
                  {/* <Route component={NotFoundPage} />
                  </Switch> */}
                  <Footer />
                </div>
              </div>
            ) : (
              <Login />
            )}
          </div>
        ) : (
          <ReactBootStrap.Spinner animation="border" />
        )}
      </Router>
    );
  }
}

export default App;
