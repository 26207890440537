"use strict";
import React from "react";
import Recap from "./InfoComposants/Recap";
import InfoNav from "./InfoComposants/InfoNav";
import Content from "./InfoComposants/Content";
import ButtonPageTitle from "../utils/button/ButtonPageTitle";
import ButtonReturn from "../utils/button/ButtonReturn";

const Info = () => {
  return (
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <ButtonPageTitle title="CONTACTS" />
      <div class="d-flex flex-column-fluid">
        <div class="container">
          <ButtonReturn fullname="Joseph DIOUF" ressource="contact" />
          <Recap />,
          <InfoNav />
          <Content />
        </div>
      </div>
    </div>
  );
};

export default Info;
