import React, { Component } from "react";

export class Step1 extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };
  render() {
    const { values, inputChange } = this.props;
    const { errors } = values;
    return (
      <div>
        <div class="pb-5">
          <h3 class="mb-10 font-weight-bold text-dark">
            Saisir les informations du fourniseur
          </h3>
          <div class="form-group">
            <label>Nom du fourniseur</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="fournisseur"
              placeholder="Société Corp."
              onChange={inputChange("FOU_NAME")}
              value={values.FOU_NAME}
            />
            <span class="form-text text-muted">
              {errors && errors.FOU_NAME}
            </span>
          </div>
          <div class="form-group">
            <label>Email</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="email"
              placeholder="societe@corp.com"
              onChange={inputChange("FOU_EMAIL")}
              value={values.FOU_EMAIL}
            />
            <span class="form-text text-muted">
              {errors && errors.FOU_EMAIL}
            </span>
          </div>
          <div class="form-group">
            <label>Téléphone</label>
            <input
              type="number"
              class="form-control form-control-solid form-control-lg"
              name="phone"
              placeholder="0033688990099"
              onChange={inputChange("FOU_PHONE")}
              value={values.FOU_PHONE}
            />
          </div>
          <div class="form-group">
            <label>Secteur d'activités</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="FOU_ZONE"
              placeholder="Immobilier"
              onChange={inputChange("FOU_ZONE")}
              value={values.FOU_ZONE}
            />
            <span class="form-text text-muted">
              {errors && errors.FOU_ZONE}
            </span>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <label>Code postal</label>
                <input
                  type="number"
                  class="form-control form-control-solid form-control-lg"
                  name="postcode"
                  placeholder="64500"
                  onChange={inputChange("FOU_ZIP_CODE")}
                  value={values.FOU_ZIP_CODE}
                />
                <span class="form-text text-muted">
                  {errors && errors.FOU_ZIP_CODE}
                </span>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <label>Adresse</label>
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="address"
                  placeholder="34 Rue ..."
                  onChange={inputChange("FOU_ADDRESS")}
                  value={values.FOU_ADDRESS}
                />
                <span class="form-text text-muted">
                  {errors && errors.FOU_ADDRESS}
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <label>Pays</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="country"
                placeholder="France"
                onChange={inputChange("FOU_COUNTRY")}
                value={values.FOU_COUNTRY}
              />
              <span class="form-text text-muted">
                {errors && errors.FOU_COUNTRY}
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between border-top mt-5 pt-10">
          <div class="mr-2">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
              onClick={this.continue}
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Step1;
