"use strict";
import React from "react";
import Logo from "./Logo";
import Menu from "./Menu";

const LeftBar = () => {
  return (
    <div
      class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
      id="kt_aside"
    >
      <Logo />
      <Menu />
    </div>
  );
};

export default LeftBar;
