import React from "react";

const Step5 = () => {
  return (
    <div class="pb-5" data-wizard-type="step-content">
      <h4 class="mb-10 font-weight-bold text-dark">
        Review your Details and Submit
      </h4>
      <h6 class="font-weight-bolder mb-3">Current Address:</h6>
      <div class="text-dark-50 line-height-lg">
        <div>Address Line 1</div>
        <div>Address Line 2</div>
        <div>Melbourne 3000, VIC, Australia</div>
      </div>
      <div class="separator separator-dashed my-5"></div>
      <h6 class="font-weight-bolder mb-3">Delivery Details:</h6>
      <div class="text-dark-50 line-height-lg">
        <div>
          Package: Complete Workstation (Monitor, Computer, Keyboard &amp;
          Mouse)
        </div>
        <div>Weight: 25kg</div>
        <div>Dimensions: 110cm (w) x 90cm (h) x 150cm (L)</div>
      </div>
      <div class="separator separator-dashed my-5"></div>
      <h6 class="font-weight-bolder mb-3">Delivery Service Type:</h6>
      <div class="text-dark-50 line-height-lg">
        <div>Overnight Delivery with Regular Packaging</div>
        <div>Preferred Morning (8:00AM - 11:00AM) Delivery</div>
      </div>
      <div class="separator separator-dashed my-5"></div>
      <h6 class="font-weight-bolder mb-3">Delivery Address:</h6>
      <div class="text-dark-50 line-height-lg">
        <div>Address Line 1</div>
        <div>Address Line 2</div>
        <div>Preston 3072, VIC, Australia</div>
      </div>
    </div>
  );
};

export default Step5;
