import React from "react";

const StepActions = () => {
  return (
    <div class="d-flex justify-content-between border-top mt-5 pt-10">
      <div class="mr-2">
        <button
          type="button"
          class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
          data-wizard-type="action-prev"
        >
          Précédent
        </button>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
          data-wizard-type="action-submit"
        >
          Enregistez
        </button>
        <button
          type="button"
          class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
          data-wizard-type="action-next"
        >
          Suivant
        </button>
      </div>
    </div>
  );
};

export default StepActions;
