import React from "react";

const Step1 = () => {
  return (
    <div
      class="pb-5"
      data-wizard-type="step-content"
      data-wizard-state="current"
    >
      <h3 class="mb-10 font-weight-bold text-dark">
        Setup Your Current Location
      </h3>
      <div class="form-group">
        <label>Address Line 1</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          name="address1"
          placeholder="Address Line 1"
          value="Address Line 1"
        />
        <span class="form-text text-muted">Please enter your Address.</span>
      </div>
      <div class="form-group">
        <label>Address Line 2</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          name="address2"
          placeholder="Address Line 2"
          value="Address Line 2"
        />
        <span class="form-text text-muted">Please enter your Address.</span>
      </div>
      <div class="row">
        <div class="col-xl-6">
          <div class="form-group">
            <label>Postcode</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="postcode"
              placeholder="Postcode"
              value="3000"
            />
            <span class="form-text text-muted">
              Please enter your Postcode.
            </span>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="form-group">
            <label>City</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="city"
              placeholder="City"
              value="Melbourne"
            />
            <span class="form-text text-muted">Please enter your City.</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6">
          <div class="form-group">
            <label>State</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="state"
              placeholder="State"
              value="VIC"
            />
            <span class="form-text text-muted">Please enter your State.</span>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="form-group">
            <label>Country</label>
            <select
              name="country"
              class="form-control form-control-solid form-control-lg"
            >
              <option value="">Select</option>
              <option value="AF">Afghanistan</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
