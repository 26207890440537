import React from "react";
import GererContact from "../../contacts/InfoComposants/GererContact";
import Autorisation from "./Autorisation";
import ContactAffecte from "./ContactAffectés";
import DetailsInfo from "./DetailsInfo";

const Content = () => {
  return (
    <div class="card card-custom ">
      <div class="card-body">
        <div class="tab-content">
          <DetailsInfo />
          <Autorisation />
          <GererContact />
          <ContactAffecte />
        </div>
      </div>
    </div>
  );
};

export default Content;
